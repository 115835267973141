import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import toast, { Toaster } from 'react-hot-toast';
import "./index.css";

const RoomPage = () => {
    const { roomId, name } = useParams();    
    const notify = () => toast.success('Doctor has been invited');

    const [viewDiv,setViewDiv] = useState(true)

    // Fetching base URL
    const baseUrl = process.env.REACT_APP_BASE_URL;
    console.log(baseUrl);

    const submitMeetLink = async () => {
        try {
            const token = "JK$;M-5~y[$vq4siT+OE_foYx!e}TlD4sfW(!2Lg@a^tPI&;h0";
            const formData = new FormData();
            formData.append("booking_id", roomId);
            const doc = sessionStorage.getItem('doctor')
            let doc_name = ""

            if(doc != null){
                doc_name = doc.replace(/ /g, "_");
            }
            console.log(doc)
            
            formData.append("meet_link", `https://meet.rioeasyconnect.com/#/room/${roomId}/${doc_name}`);
            
            const headers = {
                'Authorization': `Bearer ${token}`,
            };
        
            if(doc != null){
                const response = await fetch(`${baseUrl}/api/1/submit_meet_link`, {
                    method: 'POST',
                    headers: headers,
                    body: formData
                });
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                } else {
                    notify();
                }
            }
                
            
        } catch (error) {
            console.error("Error Booking details data:", error);
        }
    };

    const myMeeting = async (element) => {
        const doc = sessionStorage.getItem('doctor')
        let doc_name = ""
            if(doc != null){
                doc_name = doc.replace(/ /g, "_");
            }
        // const appID = 1184038461; shahim's project
        const appID = 1873082997;
        const serverSecret = "d8e884b054c57177018db64bda210369";
        const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomId, Date.now().toString(), name);
        const zc = ZegoUIKitPrebuilt.create(kitToken);
        zc.joinRoom({
            container: element,
            sharedLinks: [
                {
                    name: 'Copy link',
                    url: `https://meet.rioeasyconnect.com/#/room/${roomId}/${doc_name}`
                }
            ],
            scenario: {
                mode: ZegoUIKitPrebuilt.OneONoneCall,
            },
            showScreenSharingButton: false,
            turnOnCameraWhenJoining: true,
            turnOnMicrophoneWhenJoining: true,
            onJoinRoom: () => {
                setViewDiv(false)
                submitMeetLink();
            },
            
            onReturnToHomeScreenClicked: ()=>{
                setViewDiv(true)
                window.location.reload()

            },
            

        });
    };

    return (  
        <div className="main-div d-flex">
            {viewDiv === true ? <div className="heading">
                <h1 style={{color:"#0480b1"}}>RioEasyConnect</h1>
            </div>:""}
            <Toaster />
            <div ref={myMeeting} className="room"/>
        </div>
    );
}

export default RoomPage;

